import { createTheme } from "@material-ui/core"
import { colors } from "./assets/css/colors"

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#999999",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  //   h1: {
  //     fontSize: "32px",
  //     lineHeight: "54px",
  //     fontWeight: "bold",
  //   },
  //   h2: {
  //     fontSize: "28px",
  //     lineHeight: "48px",
  //     fontWeight: "normal",
  //   },
  //   h3: {
  //     fontSize: "25px",
  //     lineHeight: "43px",
  //     fontWeight: "300",
  //   },
  //   h4: {
  //     fontSize: "22px",
  //     fontStyle: "normal",
  //     fontWeight: "600",
  //     lineHeight: "38px",
  //   },
  //   h5: {
  //     fontSize: "20px",
  //     fontWeight: "400",
  //     lineHeight: "32px",
  //   },
  //   h6: {
  //     fontSize: "16px",
  //     fontWeight: "400",
  //     lineHeight: "28px",
  //   },

  //   subtitle1: {
  //     fontSize: "12px",
  //     fontWeight: 500,
  //     lineHeight: "15px",
  //     letterSpacing: "0em",
  //   },
  //   body1: {
  //     fontSize: "14px",
  //   },
  // },

  overrides: {
    MuiTab: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
})

export default theme
