import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
// import { Provider } from "react-redux"
// import { PersistGate } from "redux-persist/integration/react"
// import { persistor, store } from "./redux/index"
import App from "./App"
import { MuiThemeProvider } from "@material-ui/core"
import theme from "./theme"
import "./index.css"

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import store, { persistor } from "./redux/store"

// pick a date util library

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={<h1>Hello</h1>} persistor={persistor}> */}
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
              <App />
          </MuiThemeProvider>
        </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
