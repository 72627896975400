import React, { useState } from 'react';
import TopNavbar from '../../components/topNavbar';
import { Button, Divider, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import dummyWhite from "../../assets/images/download.png"
import minifeelLogo from "../../assets/images/logos/minifeel-logo.avif"
import freakinsLogo from "../../assets/images/logos/freakins-logo.webp"
import { GenericBottomDrawer2 } from '../../components/GenericBottomDrawer';
import { FiberManualRecord } from '@material-ui/icons';

import myntraLogo from "../../assets/images/logos/myntra-logo.jpeg"
import purpleLogo from "../../assets/images/logos/purple-logo.jpeg"
import urbanicLogo from "../../assets/images/logos/urbanic-logo.jpeg"

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
    //   color: "black",
      background: "white",
      position: "relative",
      borderRadius: "5px",
      marginBottom: "4px",
      height: '100vh'
    },
    brandCard: {
        height: "190px",
        // width: "130px",
        // minWidth: "130px",
        border: "1px solid black",
        borderRadius: "8px",
        position: "relative",
        marginRight: "10px",
        marginLeft: "5px",
        padding: "10px",
        marginTop: "10px",
        cursor: "pointer"
    }
}))


function BrandScreen({ isMobile }) {

    const classes = useStyles();

    const brands = [
        {"name": "Minifeel", bottomText: "Upto 10% Commission", image: minifeelLogo,
         brandText: "Recommend your favorite products from Minifeel and earn Flat 10% on every successful order!",
         brandWebsite: "https://minifeel.in"
        },
        {"name": "Freakins", bottomText: "Upto 10% Commission", image: freakinsLogo,
         brandText: "Recommend your favorite products from Freakins and earn Flat 10% on every successful order!",
         brandWebsite: "https://freakins.com"
        },
        {"name": "Myntra", bottomText: "Upto 10% Commission", image: myntraLogo,
         brandText: "Recommend your favorite products from Myntra and earn Flat 10% on every successful order!",
         brandWebsite: ""
        },
        {"name": "Purple", bottomText: "Upto 10% Commission", image: purpleLogo,
         brandText: "Recommend your favorite products from Purple and earn Flat 10% on every successful order!",
         brandWebsite: ""
        },
        {"name": "Urbanic", bottomText: "Upto 10% Commission", image: urbanicLogo,
         brandText: "Recommend your favorite products from Urbanic and earn Flat 10% on every successful order!",
         brandWebsite: ""
        },
    ]

    const [openDrawer, setOpenDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})

    return (
        <React.Fragment>
            <TopNavbar isMobile={isMobile} screen={"home"}/>
            <Grid container style={{marginTop: "20%"}}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                                    Brand By Category
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: "12px", opacity: 0.6, marginTop: "-1%"}}>
                                    Check out the list of brands to partner with
                                </Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                {brands?.map((val, idx) => (
                                    <Grid item xs={6} onClick={() => {
                                        setOpenDrawer(true);
                                        setDrawerContent({...val});
                                    }}>
                                        <Paper className={classes.brandCard} elevation={0}>
                                            <Grid container justifyContent="center" spacing={1}>
                                                <Grid item>
                                                    <Paper elevation={2} style={{borderRadius: "50px", height: "70px", width: "70px", marginTop: "15%", padding: 5}}>
                                                        <img src={val?.image} style={{height: "70px", width: "70px", borderRadius: "50px", objectFit: "contain"}}/>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12}></Grid>
                                                <Grid item>
                                                    <Typography style={{fontSize: "15px",  fontWeight: "500"}}>
                                                        {val?.name}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={12}></Grid> */}
                                                <Grid item>
                                                    <Typography style={{fontSize: "14px", textAlign: "center"}}>
                                                        {val?.bottomText}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <GenericBottomDrawer2 open={openDrawer} setOpen={setOpenDrawer} isMobile={isMobile}
                                drawerContent={(
                                    <React.Fragment>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} container spacing={1} justifyContent='center'>
                                                <Grid item>
                                                    <Paper style={{height: "75px", width: "75px", padding: 5, borderRadius: "40px"}}>
                                                        <img src={drawerContent?.image} style={{height: "75px", width: "75px", borderRadius: "50px", objectFit: "contain"}}/>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12}></Grid>
                                                <Grid item>
                                                    <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                                                        {drawerContent?.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} style={{display: "flex"}}>
                                                <FiberManualRecord style={{fontSize: "8px", color: "black", marginRight: "10px", marginTop: '10px'}} />
                                                <Typography style={{fontSize: "14px", color: "grey"}}>
                                                    {drawerContent?.brandText}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider style={{color: "black", background: "rgba(0, 0, 0, 0.1)"}} />
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <Button variant="outlined" color="primary" fullWidth style={{borderRadius: "10px", fontWeight: "500", border: "1px solid"}}
                                                    onClick={() => window.open(drawerContent?.brandWebsite)}
                                                >
                                                    Go to brand website
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            
        </React.Fragment>
    )
}


export default BrandScreen