import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, Divider, LinearProgress, withStyles, Avatar } from '@material-ui/core';

import profilePic from "../../assets/images/lissha_prpr.jpg"
import instaLogo from "../../assets/images/instagram.png"

import plumIcon from "../../assets/images/dummy_photos/KGPe5r5ojrUr1694595572365.png"

import dummyPhoto1 from "../../assets/images/dummy_photos/318979404_552826566263952_734592534680343139_n.jpg";
import dummyPhoto2 from "../../assets/images/dummy_photos/328437972_211997201313893_3458513646514237340_n.jpg";
import dummyPhoto3 from "../../assets/images/dummy_photos/318517827_1113616329346439_6160478259138521838_n.jpg";
import dummyPhoto4 from "../../assets/images/dummy_photos/Vanilla-Caramello_Body-Lotion2_750x.webp";

import dummyPhoto5 from "../../assets/images/dummy_photos/779116795582952285cm3nvcpnjgcatalog.jpg";
import dummyPhoto90 from "../../assets/images/dummy_photos/01_3b34de21-6b7b-4a44-afcd-be0f4b4002ce_1024x1024.webp";
import dummyPhoto91 from "../../assets/images/dummy_photos/01-_1_695374cd-8c32-46da-985a-40d3964860d4_750x.webp";

import dummyVideo1 from "../../assets/videos/2989296311522944310.mp4"
import dummyVideo2 from "../../assets/videos/dummy-video122.mp4"

import whatsappPng from "../../assets/images/whatsapp.png"

import paperplaneLogo from "../../assets/images/logos/PaperPlaneIcon.png";
import wishlistIcon from "../../assets/images/wishlist.png";
import { BottomBar, StoreNav } from '../homePage';
import { colors } from "../../assets/css/colors"
import { ExpandMore, Favorite, FavoriteBorderOutlined, LocalMallOutlined, Star, StarHalf, StarOutline, Storefront, WhatsApp } from '@material-ui/icons';
import { PaymentDrawer } from '../bottomDrawer/paymentDrawer';

import { useLocation, useHistory } from "react-router-dom"
import CustomToast from '../customToast';


const iconUrls = [
  "https://website-paperplane.s3.amazonaws.com/avatar/av1.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av2.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av3.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av4.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av5.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av6.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av7.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av8.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av9.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av10.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av11.png",
  "https://website-paperplane.s3.amazonaws.com/avatar/av12.png",
]


const createStyledProgress = (colorPrimary = "#1c1c1c", barColor = "#FF8042") => {
    return withStyles((theme) => ({
      root: {
        height: 5,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      },
      bar: {
        borderRadius: 5,
        backgroundColor: barColor,
      },
    }))(LinearProgress);
  };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    background: "#1c1c1c",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowY: 'auto',

    // overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    background: "#1c1c1c",

    // textAlign: 'center',
    color: theme.palette.text.secondary,
    position: "relative",
    marginTop: "5px",
    border: "0.2px solid rgba(255, 255, 255, 0.2)",

  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    // position: "absolute",
    // top: "4.8rem",
    // left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));

const CustomGrid = styled(Grid)({
    // Set a fixed height for the container
    // height: "70vh",
    // Enable scrolling
    overflowY: 'scroll',
    overflowX: "scroll",
    // Hide the scrollbar
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 1, // Adjust to desired thickness
      height: 0,
      backgroundColor: "transparent",
      // display: "none"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white', // Adjust to desired color
      position: "absolute",
    },
  });

  const colors__ = ['#7856FF', '#FF7557', '#80E1D9', '#F8BC3B', '#B2596E']

const ViewItem = ({isMobile, otherProps, login, setLogin, allProfileData, setAddItemToast, setRemoveItemToast, updateWishListItem, openBottomBar, setOpenBottomBar, wishListItem}) => {
  const classes = useStyles();

  const location = useLocation()
  const history = useHistory()

  const [productItems, setProductItems] = useState([])
  useEffect(() => {
    if(allProfileData?.productCollection){
      setProductItems([...allProfileData?.productCollection])
    }
  }, [allProfileData])

  const [productItem, setProductItem] = useState(null)
  const [waToast, setWaToast] = useState(false)

  useEffect(() => {
    if (location?.pathname && productItems?.length) {
      let productId = location?.pathname?.split("/view/");
      productId = productId[1];

      let productItem_ = productItems.find(f => f?.productId === Number(productId));

      setProductItem({...productItem_})
    }
  }, [location, productItems])

  const sendMessage = (mobile) => {
    if(mobile?.length) {

      const body = JSON.stringify({ 
        "mobileNo": mobile,
        ...productItem
      });

      fetch('https://pplane-api-prod-pcs.paperplane.health/api/v1/auth/store-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body
        });
    }
  }


  // const coverPic = () => {
  //   const coverPicStyle = {
  //     textAlign: 'center',
  //     height: '7.25rem',
  //     minWidth: '100%',
  //     borderRadius: '0.5rem',
  //     backgroundImage: 'url("https://d3g01po1nkka75.cloudfront.net/Cover2.png")',
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center center',
  //   };
  
  //   return (
  //     <div className="cover-pic-wrapper" style={coverPicStyle}>
  //       {/* You can add any additional content or components within this div */}
  //     </div>
  //   );
  // };


  // const curratedPic = (curatePic) => {

  //   const coverPicStyle = {
  //     textAlign: 'center',
  //     height: '21rem',
  //     width: '13rem',
  //     minWidth: '12rem',
  //     marginLeft: 10,
  //     marginBottom: 10,
  //     borderRadius: '0.5rem',
  //     backgroundImage: `url(${curatePic})`,
  //     backgroundSize: 'cover',
  //     backgroundPosition: 'center center',
  //   };
  
  //   return (
  //     <div className="cover-pic-wrapper" style={coverPicStyle}>
  //       {/* You can add any additional content or components within this div */}
  //     </div>
  //   );
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // const curatedMedia = [
  //   { type: 'image', src: dummyPhoto2, alt: 'Image 1' },
  //   { type: 'image', src: dummyPhoto3, alt: 'Image 2' },
  //   // Add more images and videos as needed
  // ];

  const [redirectTo, setRedirectTo] = useState(null)
  const [paymentDrawer, setPaymentDrawer] = useState(false)

  const [bottomBarParam, setBottomBarParam] = useState({})

  const wishlistClick = (type = null) => {
    setOpenBottomBar(true)
    setRedirectTo(type)

    if(type === "cart") {
      setBottomBarParam({
        "captionText": "Please enter your Whatsapp number to get the link, you can use it to save this product and order later!"
      })
    }
  }


  const [productImages, setProductImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(0)

  useEffect(() => {
    if(productItem){
      let images = [productItem?.imageUrl, ...(productItem?.otherImages?.length ? productItem?.otherImages : [])];

      setProductImages([...images])
    }
  }, [productItem])

  const [isWishlisted, setIsWishlisted] = useState(false)
  useEffect(() => {
    if(wishListItem?.length){
      wishListItem?.map(v => {
        if(v?.id === productItem?.id)
          setIsWishlisted(true)
      })
    }
  }, [productItem])

  return (
    <React.Fragment>


    {/* <div className={classes.root}> */}
        <StoreNav defaultView={true} isMobile={isMobile} wishlistClick={wishlistClick} login={login} setLogin={setLogin} profileName={allProfileData?.name} 
        profilePic={allProfileData?.profilePic}
        allProfileData={allProfileData}
        />
      <Grid container justifyContent='center' className={classes.root}>

        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0} >
            {/* {coverPic()} */}

            
            <Grid container spacing={1}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} container justifyContent='center'>
                    <Grid item style={{position: "relative"}}>
                        <img src={productImages?.[selectedImage]} style={{height: "18rem", width: "12rem", objectFit: "contain", borderRadius: "5px"}} />
                        <div style={{position: "absolute", top: 0, right: -50}}>
                          <IconButton onClick={() => {
                            setIsWishlisted(!isWishlisted)
                            if(!isWishlisted) {
                              updateWishListItem(productItem, "add")
                              setAddItemToast(true)
                            } else {
                              updateWishListItem(productItem, "removeById", 0, productItem?.id)
                              setRemoveItemToast(true)
                            }
                          }}>
                            {isWishlisted ? <Favorite style={{color: "white"}} /> : <FavoriteBorderOutlined style={{color: "white"}} />}
                          </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    {productItem?.otherImages?.length ? <Grid item>
                      <Grid container style={{marginTop: "-20px", marginBottom: "30px"}}>
                        <Grid item xs={3}></Grid>
                        <CustomGrid item xs={6} style={{display: "flex", overflowX: "scroll", maxWidth: isMobile ? "400px" : null}}>
                          {productImages?.map((v, i) => (
                            <img src={v} style={{height: "40px", width: "40px", marginRight: "10px",
                              cursor: "pointer",
                              padding: 5,
                              border: i === selectedImage ? "solid 1px white" : null
                            }}
                            onClick={() => {
                              setSelectedImage(i)
                            }}
                            />
                          ))}
                        </CustomGrid>
                      </Grid>
                    </Grid> : null}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container>
                            <Grid item xs={6}>
                                <Typography variant="h5" style={{fontSize: "20px", color: "white"}}>
                                  {productItem?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent='right'>
                                <Grid item style={{marginLeft: 'auto'}}>
                                  <div style={{display: "flex"}}>
                                    <Typography variant="h5" style={{fontSize: "20px", fontWeight: "bold"}}>₹ {productItem?.discountedPrice}</Typography>
                                    <Typography variant="h5" style={{fontSize: "18px", marginLeft: 10, opacity: 0.5, textDecoration: "line-through"}}>₹ {productItem?.price} </Typography>
                                  </div>
                                  <div style={{textAlign: "right", marginTop: "5px"}}>
                                    <Typography variant="h5" style={{fontSize: "14px", color: colors.secondary}}>({productItem?.offerPercentage}% OFF)</Typography>
                                  </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant="body1">Item Description</Typography>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                        <Button variant="contained" color="primary" className="buy-button">
                            Buy
                        </Button>
                        <Button variant="contained" color="secondary" className="go-to-cart-button">
                            Go to Cart
                        </Button>
                        </Grid> */}
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{display: "flex", marginTop: "10px"}}>
                    <Button startIcon={<WhatsApp style={{color: "white"}} />} variant='contained' onClick={() => wishlistClick("cart")} style={{borderRadius: "20px", marginRight: "10px", color: "white", background: "#07E676"}} disableElevation>
                        Save to Whatsapp
                    </Button>

                    <Button variant='outlined' onClick={() => {
                      // window.open(productItem?.productPage);
                      wishlistClick("payment");
                    }} style={{borderRadius: "20px", color: "white", borderColor: "white"}} disableElevation>
                        Buy Now
                    </Button>
                </Grid>

                <Grid item xs={12} style={{marginTop: "10px"}}>
                    <Typography variant='h6' style={{fontSize: "16px", color: "white"}}>
                        Product Description
                    </Typography>
                    <Typography variant='h6' style={{fontSize: "12px", marginTop: 10, marginBottom: 10}}>
                      {productItem?.productDescription}
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{
                    // height: "150px",
    border: "0.2px solid rgba(255, 255, 255, 0.2)",
                    width: "100%",
                    borderRadius: "20px",
                    background: "#1c1c1c"
                  }} container>
                  <Grid item xs={12} style={{display: "flex", alignItems: "center", padding: 10}}>
                    <img src={plumIcon} style={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "40px",
                      marginRight: "10px"
                    }} />

                    <Typography variant="caption">
                      Fulfilled by
                      <Typography style={{fontSize: "14px", fontWeight: "500"}}>
                        Plum
                      </Typography>
                    </Typography>
                    
                    <Button variant="text" startIcon={<Storefront color='primary' />} style={{marginLeft: "auto", fontSize: "10px", color: "white"}} onClick={() => window.open("https://plumgoodness.com")}>
                      Visit  Brand
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{color: "red", background: "rgba(255, 255, 255, 0.2)"}}/>
                  </Grid>
                  <Grid item xs={12} style={{display: "flex", alignItems: "center", padding: 10}}>
                    <img src={allProfileData?.profilePic} style={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "40px",
                      marginRight: "10px"
                    }} />
                    
                    <Typography variant="caption">
                      Curated for you by
                      <Typography style={{fontSize: "14px", fontWeight: "500"}}>
                        {allProfileData?.name}
                      </Typography>
                    </Typography>

                    <Button variant="text" startIcon={<LocalMallOutlined color='primary' />} style={{marginLeft: "auto", fontSize: "10px", color: "white"}} 
                      onClick={() => history.push(`/${allProfileData?.username}`)}
                    >
                      Visit  Store
                    </Button>
                  </Grid>
                </Grid>

                <Grid item container spacing={1} xs={12} style={{marginTop: "10px"}}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={{fontSize: "16px", color: "white"}}>
                          Ratings & Reviews
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems='center'>
                      <Grid item xs={5} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant='h2' style={{fontWeight: "bold"}}>
                          4.7
                          <div style={{fontSize: "11px", fontWeight: "400", textAlign: "center"}}>
                            265 Reviews
                          </div>
                        </Typography>

                      </Grid>
                      <Grid item xs={7}>
                        {[201, 27, 20, 14, 3].map((v, i) => {
                         let BorderLinearProgress = createStyledProgress("#1c1c1c", colors__[i])
                        return (
                            <div style={{display: "flex", alignItems: 'center'}}>
                              <div>
                                <Typography style={{fontSize: "10px", marginRight: "5px"}}>
                                  {5-i}
                                </Typography>
                              </div>
                              <div style={{width: "80%"}}>
                                <BorderLinearProgress key={i} variant='determinate' value={(v/265)*100} color='primary' /> 
                              </div>
                              <div>
                                <Typography style={{fontSize: "10px", marginLeft: "5px"}}>
                                  {v}
                                </Typography>
                              </div>
                            </div>)
                          
                        }
                        )}

                      </Grid>
                    </Grid>

                    <Grid item container xs={12} alignItems='center' spacing={1} style={{marginTop: "10px"}}>
                        <Grid item>
                        <img src={iconUrls[0]} style={{width: "20px", height: "20px"}}/>
                                                </Grid>
                        <Grid item>
                          <Typography style={{fontSize: "14px", marginBottom: "5px"}}>
                            Muskan
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{fontWeight: "bold", fontSize: "12px"}}>
                            Most amazing lotion
                          </Typography>
                          <Typography variant='subtitle1' style={{fontSize: "12px"}}>
                            Its damn good for oily skin because it has gel consistency and niacinamide, its not greasy and brighten up the skin.
                          </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} alignItems='center' spacing={1} style={{marginTop: "10px"}}>
                        <Grid item>
                        <img src={iconUrls[5]} style={{width: "20px", height: "20px"}}/>
                        </Grid>
                        <Grid item>
                          <Typography style={{fontSize: "14px", marginBottom: "5px"}}>
                            Chaitanya
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <StarHalf style={{color: "yellow", fontSize: "12px"}} />
                          <StarOutline style={{color: "yellow", fontSize:"12px"}}></StarOutline>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{fontWeight: "bold", fontSize: "12px"}}>
                            Good gel lotion
                          </Typography>
                          <Typography variant='subtitle1' style={{fontSize: "12px"}}>
                            I like the gel consistency of this product, good lotion for my combination skin.
                          </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} alignItems='center' spacing={1} style={{marginTop: "10px"}}>
                        <Grid item>
                        <img src={iconUrls[2]} style={{width: "20px", height: "20px"}}/>
                        </Grid>
                        <Grid item>
                          <Typography style={{fontSize: "14px", marginBottom: "5px"}}>
                            Varun Goel
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <StarHalf style={{color: "yellow", fontSize: "12px"}} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{fontWeight: "bold", fontSize: "12px"}}>
                            Must try
                          </Typography>
                          <Typography variant='subtitle1' style={{fontSize: "12px"}}>
                            Must have skin care product, it revitalizes and smoothens the skin, providing a refreshing and long-lasting moisturizing effect. A go-to-choice for maintaining a healthy and radiant complexion.  
                          </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} alignItems='center' spacing={1} style={{marginTop: "10px"}}>
                        <Grid item>
                        <img src={iconUrls[3]} style={{width: "20px", height: "20px"}}/>
                        </Grid>
                        <Grid item>
                          <Typography style={{fontSize: "14px", marginBottom: "5px"}}>
                            Pavani John
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <Star style={{color: "yellow", fontSize: "12px"}} />
                          <StarOutline style={{color: "yellow", fontSize: "12px"}} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{fontWeight: "bold", fontSize: "12px"}}>
                            Helps in reduction of acne and blemishes.
                          </Typography>
                          <Typography variant='subtitle1' style={{fontSize: "12px"}}>
                            I am literally addicted to this product, it is best for someone who has acne and has oily skin. It is best for reducing acne and blemishes. 
                          </Typography>
                        </Grid>
                    </Grid>

                    <Grid item>
                      <Button endIcon={<ExpandMore/>} variant='text' style={{color: "white"}}>
                        All Reviews
                      </Button>
                    </Grid>
                </Grid>

               {[...allProfileData?.curatedCollection]?.filter(f => f?.type === "image")?.length ? <Grid item xs={12} container spacing={2} style={{marginTop: "10px"}}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{fontSize: "16px", color: "white"}}>
                            Similar Products
                        </Typography>
                    </Grid>
                    <CustomGrid item xs={12}>
                        <CustomGrid className={classes.imagesSection}>
                        {[...allProfileData?.curatedCollection]?.filter(f => f?.type === "image").map((item, index) => (
                            (
                            <img src={item?.src} style={{height: "15rem", width: "9rem", borderRadius: "0.5rem", marginLeft: "10px", marginBottom: "10px"}} />
                            ) 
                        ))}
                        </CustomGrid>
                </CustomGrid>
                </Grid> : null}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={3}>
          <BottomBar isMobile={isMobile} open={openBottomBar} setOpen={(val) => {
            setOpenBottomBar(val);
            if(!val) {
              setBottomBarParam({})
            }
          }} setLogin={setLogin} onLogin={redirectTo === "payment" ? (() => {
            setPaymentDrawer(true)
            setRedirectTo(null)
          }) : (redirectTo === "cart" ? ((mobile) => {
            // alert(mobile)
            setWaToast(true)
            sendMessage(mobile)
            setRedirectTo(null)
            setBottomBarParam({})
          }) : null)} allProfileData={allProfileData} {...bottomBarParam}/>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={3}>
          <PaymentDrawer isMobile={isMobile} isBottomBarOpen={paymentDrawer} setIsBottomBarOpen={setPaymentDrawer}/>
        </Grid>

        {waToast ? <CustomToast 
          addItem={false}
          open={waToast}
          onClose={() => setWaToast(false)}
          customMessage={"Sent mesasge on your whatsapp!"}
          imagePng={whatsappPng}
          // actionFunc={() => setOpenBottomBar(true)}
        /> : null}

      </Grid>
    {/* </div> */}
    </React.Fragment>
  );
};

export default ViewItem;

