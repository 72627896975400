// src/store/index.js

// import { createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {thunk} from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers/index';

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  };
  
const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
//   middleware: (getDefaultMiddleware) => {
//     // Customize middleware here
//     // For example, you can add additional middleware
//     return getDefaultMiddleware().concat(composeWithDevTools(applyMiddleware(thunk)));
//   },
});

export const persistor = persistStore(store);

export default store;
