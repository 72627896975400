import { Drawer, Grid, Paper, SwipeableDrawer } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {useHistory} from "react-router-dom"


function GenericBottomDrawer({ open, setOpen, drawerContent, isMobile, elevation = 0, maxHeight, zIndex = 3 }) {

    const [isBottomBarOpen, setIsBottomBarOpen] = useState(true);

    const history = useHistory();

    useEffect(() => {
        setIsBottomBarOpen(open);
    }, [String(open)])

    const touchStartY = useRef(0);

    const addSwipeListeners = (event) => {
        touchStartY.current = event?.touches?.[0]?.clientY;
    };

    const removeSwipeListeners = (event) => {
        
        const endY = event?.changedTouches?.[0]?.clientY;
        try{
        const deltaY = endY - touchStartY?.current;
        if (deltaY > 50) {
            setIsBottomBarOpen(false);
            if(setOpen)
            setOpen(false)
        }
        } catch (err) {
        console.log("..", err);
        }
    };

    return (
        <React.Fragment>
            {isBottomBarOpen ? <>
            {/* <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 99999,
                pointerEvents: "none"
            }}></div> */}

      <Paper
      elevation={elevation} 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={{height: "auto", background: "white", zIndex: zIndex, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22.2%", 
        padding: isMobile ? "5%" : "1.4%",
        transition: "height 2s",
        maxHeight: maxHeight ?? null
        // boxShadow: elevation ? "0px 5px 10px 5px whitesmoke" : null
        }}>
            {/* <Grid container justifyContent='center'>
                <Grid item>
                    <div style={{width: "50px", height: "10px", backgroundColor: "grey", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
                        setIsBottomBarOpen(false)
                    }}></div>
                </Grid>
            </Grid> */}
            {drawerContent}
      </Paper></> : null}
        </React.Fragment>
    )
}


export default GenericBottomDrawer;



export function GenericBottomDrawer2({ open, setOpen, drawerContent, isMobile, elevation = 0, maxHeight, zIndex = 3 }) {

    const [isBottomBarOpen, setIsBottomBarOpen] = useState(true);

    const history = useHistory();

    useEffect(() => {
        setIsBottomBarOpen(open);
    }, [String(open)])

    const touchStartY = useRef(0);

    const addSwipeListeners = (event) => {
        touchStartY.current = event?.touches?.[0]?.clientY;
    };

    const removeSwipeListeners = (event) => {
        
        const endY = event?.changedTouches?.[0]?.clientY;
        try{
        const deltaY = endY - touchStartY?.current;
        if (deltaY > 50) {
            setIsBottomBarOpen(false);
            if(setOpen)
            setOpen(false)
        }
        } catch (err) {
        console.log("..", err);
        }
    };

    return (
        <React.Fragment>
            {isBottomBarOpen ? <>
            {/* <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 99999,
                pointerEvents: "none"
            }}></div> */}

        <SwipeableDrawer anchor={"bottom"} open={isBottomBarOpen} onClose={() => {
            setIsBottomBarOpen(false)
            if(setOpen) {
                setOpen(false)
            }
        }}
                PaperProps={{elevation: elevation, style: {
                    marginLeft: isMobile ? null : "37.5%",
                    overflow: "hidden",
                    height: "auto", background: "white", zIndex: zIndex, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22.2%", 
                padding: isMobile ? "5%" : "1.4%",
                transition: "height 2s",
                maxHeight: maxHeight ?? null
                // boxShadow: elevation ? "0px 5px 10px 5px whitesmoke" : null
                }}}
            >
                <Grid container justifyContent='center'>
                    <Grid item>
                        <div style={{width: "50px", height: "5px", backgroundColor: "rgba(0, 0, 0, 0.1)", marginRight: "10px", borderRadius: "5px", cursor: "pointer", marginBottom: "20px"}} onClick={() => {
                            setIsBottomBarOpen(false)
                            if(setOpen) {
                                setOpen(false)
                            }
                        }}></div>
                    </Grid>
                </Grid>
                {drawerContent}
            </SwipeableDrawer>
      {/* <Paper
      elevation={elevation} 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={}> 
      {/* </Paper> */}
      </> : null}
        </React.Fragment>
    )
}
