
import React, { useEffect, useState } from 'react';
import ProfilePage from './components/homePage';
import { Route, Switch, Redirect, useLocation } from "react-router-dom"
import ViewItem from './components/viewItem';
import { Grid, makeStyles, styled, useTheme, withWidth } from '@material-ui/core';
import './App.css'
import WishList from './components/wishlist';
import CustomToast from './components/customToast';
import AuthScreen from './views/AuthScreen';
import MainPage from './views/MainPage';
import {useHistory} from "react-router-dom"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowY: 'auto',

    // overflowX: "hidden"
  },
}))

export const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: "70vh",
  // Enable scrolling
  overflowY: 'scroll',
  overflowX: "scroll",
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 0, // Adjust to desired thickness
    height: 0,
    backgroundColor: "transparent",
    // display: "none"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'white', // Adjust to desired color
    position: "absolute",
  },
});

function App({width}) {

  const isMobile = width === "xs" || width === "sm" ? true : false
  const classes = useStyles()

  const history = useHistory()


  return (
    <React.Fragment>
      <Grid container justifyContent='center' className={classes.root}>
        <CustomGrid item xs={12} md={3} style={{height: "100vh", overflowY: "scroll"}}>
          <Switch>
              <Route path="/auth" render={(props) => <AuthScreen {...props} isMobile={isMobile} />} />
              <Route path="/main" render={(props) => <MainPage {...props} isMobile={isMobile} />} />

            {/*<Route
              path="/verificationPending"
              render={(props) => <PendingVerification />}
            />
            <Route path="/meet" render={(props) => <MeetLayout {...props} />} /> */}

            {/* <Route path='/' render={(props) => <IndexView {...props} />} /> */}
            <Redirect from="/" to={`/auth`} />
            <Redirect from="*" to={`/auth`} />
          </Switch>
        </CustomGrid>
      </Grid>

    </React.Fragment>

      


  )
}


export default withWidth()(App)
