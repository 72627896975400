import { ADD_PROFILE_DATA } from "../constants"


const initialState = {
  access_token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  auth_error_msg: "",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROFILE_DATA: {
      return {
        ...state,
        "user": {...action.payload},
      }
    }

    default:
      return state
  }
}
