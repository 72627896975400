// src/store/reducers/index.js

import { combineReducers } from 'redux';
// import your reducers here
import authReducer from './auth.reducer';

const rootReducer = combineReducers({
  // your reducers go here
  "auth": authReducer
});

export default rootReducer;
