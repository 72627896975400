import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, Divider } from '@material-ui/core';

import profilePic from "../../assets/images/lissha_prpr.jpg"
import instaLogo from "../../assets/images/instagram.png"

import plumIcon from "../../assets/images/dummy_photos/KGPe5r5ojrUr1694595572365.png"

import dummyPhoto1 from "../../assets/images/dummy_photos/318979404_552826566263952_734592534680343139_n.jpg";
import dummyPhoto2 from "../../assets/images/dummy_photos/328437972_211997201313893_3458513646514237340_n.jpg";
import dummyPhoto3 from "../../assets/images/dummy_photos/318517827_1113616329346439_6160478259138521838_n.jpg";
import dummyPhoto4 from "../../assets/images/dummy_photos/Vanilla-Caramello_Body-Lotion2_750x.webp";

import dummyPhoto5 from "../../assets/images/dummy_photos/779116795582952285cm3nvcpnjgcatalog.jpg";

import dummyVideo1 from "../../assets/videos/2989296311522944310.mp4"
import dummyVideo2 from "../../assets/videos/dummy-video122.mp4"

import paperplaneLogo from "../../assets/images/logos/PaperPlaneIcon.png";
import wishlistIcon from "../../assets/images/wishlist.png";
import { BottomBar, StoreNav } from '../homePage';
import { colors } from "../../assets/css/colors"
import { Favorite, FavoriteBorderOutlined, LocalMallOutlined, Storefront } from '@material-ui/icons';

import noItemImage from "../../assets/images/dummy_photos/1000_F_601177928_HMQkTQ9teIr6WmFGuCWl4iXNQH3pacyf-transformed.png";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowY: 'auto',
    // overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    position: "relative",
    marginTop: "5px"
  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'black',
    fontSize: '18px',
    // fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    // position: "absolute",
    // top: "4.8rem",
    // left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));

const CustomGrid = styled(Grid)({
    // Set a fixed height for the container
    // height: "70vh",
    // Enable scrolling
    overflowY: 'scroll',
    // Hide the scrollbar
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 1, // Adjust to desired thickness
      height: 0,
      backgroundColor: "transparent",
      // display: "none"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'black', // Adjust to desired color
      position: "absolute",
    },
  });


const WishList = ({isMobile, otherProps, wishListItem, updateWishListItem, setAddItemToast, setRemoveItemToast, allProfileData}) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [imagesAndVideos, setImageAndVideos] = useState([]);

  useEffect(() => {
    if(wishListItem?.length){
      let tempIaV = [...allProfileData?.productCollection].map(v => {
        if(wishListItem?.find(f => f.id === v.id)){
          return {...v, wishlisted: true}
        } else {
          return {...v}
        }
      });
  
      setImageAndVideos([...tempIaV])
    }
  }, [])

  const [openBottomBar, setOpenBottomBar] = useState(false)
  const wishlistClick = () => {
    setOpenBottomBar(true)
  }


  const updateInWishList = (index, tempState, type = "add") => {
    switch(type) {
      case "add": {

        let updatedTempState = [...tempState];
        updatedTempState[index].wishlisted = true;

        updateWishListItem(updatedTempState[index], "add")
        
        break;
      }

      case "remove": {

        let updatedTempState = [...tempState];
        updatedTempState[index].wishlisted = false;

        updateWishListItem(updatedTempState[index], "removeById", 0, updatedTempState?.[index]?.id)
        
        break;
      }
    }
  }


  return (
    <React.Fragment>


    {/* <div className={classes.root}> */}
        <StoreNav defaultView={true} isMobile={isMobile} headerText={"Your Wishlist"} allProfileData={allProfileData}/>
      <Grid container justifyContent='center' className={classes.root}>

        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0} >
            {/* {coverPic()} */}

            
            <Grid container spacing={1}>

                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    Wishlisted Products
                  </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Chip label={"All"} variant='default' style={{background: colors.primaryGradient}} color='primary'/>
                  <Chip label={"From Creators"} variant='outlined' style={{marginLeft: 5}} color='primary'/>
                </Grid>

                <Grid item xs={12}></Grid>

                {!wishListItem?.length ? <Grid item xs={12} style={{textAlign: "center"}}>
                    <img src={noItemImage} style={{height: "auto", width: "60%"}}/>

                    <Typography style={{color: "black", fontSize: "14px"}}>
                        You haven't wishlisted any products here!
                    </Typography>
                    <Typography style={{fontSize: "12px", marginTop: 5}}>
                        Tap on the heart icon on the product you like to see them here.
                    </Typography>
                </Grid> : <Grid item xs={12} container spacing={1} alignItems='center'>
                  {[...wishListItem].map((item, index) => (
                    <Grid item xs={6} key={index} style={{position: "relative", width: "100%", maxWidth: "100%"}}>
                      {item?.type === 'image' ? (
                        <div>
                          <img src={item?.imageUrl} style={{height: "160px", width: "100%", minWidth: "100%", display: "block", objectFit: "contain"}} />
                          <div style={{padding: 10}}>
                            <Typography style={{fontSize: "12px", color: "black"}}>
                              {item?.name ?? "Beauty and Skin Product"}
                            </Typography>
                            {item?.name ? <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                              <Typography style={{fontSize: "12px", color: "black"}}>
                              ₹ {item?.discountedPrice}
                              </Typography>
                              <Typography variant="h5" style={{fontSize: "10px", marginLeft: 5, opacity: 0.5, textDecoration: "line-through"}}>₹ {item?.price} </Typography>
                              <Typography variant="h5" style={{fontSize: "10px", marginLeft: 5, color: colors.secondary}}>({item?.offerPercentage}% OFF)</Typography>
                            </div> : null}

                            <div>
                              <Button variant="contained" disableElevation fullWidth style={{background: "black", color: "white", marginTop: 10, borderRadius: "20px"}}>
                                Shop now
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <video style={{height: "auto", width: "100%", borderRadius: "0.5rem", display: "block"}}
                          autoPlay muted 
                        >
                          <source src={item?.src} />
                        </video>
                      )}
                      {item?.type === 'video' ? <div style={{position: "absolute", bottom: item?.marginCaption ?? 0, padding: 10, left: 0, background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1))", width: "87%" }}>
                        <Typography style={{fontSize: "16px", color: "white"}}>
                          Beauty and Skin Products
                        </Typography>
                      </div> : null}

                      <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation();
                          updateWishListItem(item, "removeByIndex", index);
                          setRemoveItemToast(true)
                        }}>
                          <Favorite style={{color: item?.type === 'image' ? "black" : "white"}} />
                        </IconButton>
                      </div>
                  </Grid>
        
                  ))}
                </Grid>}

                


                {/* <Grid item xs={12} container spacing={2} style={{marginTop: "10px"}}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{fontSize: "16px", color: "black"}}>
                            Similar Products
                        </Typography>
                    </Grid>
                    <CustomGrid item xs={12}>
                        <CustomGrid className={classes.imagesSection}>
                        {[...curatedMedia].map((item, index) => (
                            (
                            <img src={item?.src} style={{height: "15rem", width: "9rem", borderRadius: "0.5rem", marginLeft: "10px", marginBottom: "10px"}} />
                            ) 
                        ))}
                        </CustomGrid>
                    </CustomGrid>
                </Grid> */}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0} >
            {/* {coverPic()} */}

            
            <Grid container spacing={1}>

                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    Recently Viewed
                  </Typography>

                  <Typography variant="subtitle1" style={{fontSize: 14}}>
                    Pick up where you left off. Continue shopping for all your favorite products!
                  </Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <CustomGrid item container xs={12}>
                {[...allProfileData?.productCollection]?.slice(0, 2)?.map((item, index) => (
                    <Grid item xs={6} key={index} style={{position: "relative", width: "100%", maxWidth: "100%"}}>
                      {item?.type === 'image' ? (
                        <div>
                          <img src={item?.imageUrl} style={{height: "160px", width: "100%", minWidth: "100%", display: "block", objectFit: "contain"}} />
                          <div style={{padding: 10}}>
                            <Typography style={{fontSize: "12px", color: "black"}}>
                              {item?.name ?? "Beauty and Skin Product"}
                            </Typography>
                            {item?.name ? <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                              <Typography style={{fontSize: "12px", color: "black"}}>
                              ₹ {item?.discountedPrice}
                              </Typography>
                              <Typography variant="h5" style={{fontSize: "10px", marginLeft: 5, opacity: 0.5, textDecoration: "line-through"}}>₹ {item?.price} </Typography>
                              <Typography variant="h5" style={{fontSize: "10px", marginLeft: 5, color: colors.secondary}}>({item?.offerPercentage}% OFF)</Typography>
                            </div> : null}
                          </div>
                        </div>
                      ) : (
                        <video style={{height: "350px", width: "100%", borderRadius: "1rem", display: "block", objectFit: "cover"}} 
                          // onMouseEnter={() => playVideo(videoRef, item?.videoIndex)}
                          // onMouseLeave={() => pauseVideo(videoRef, item?.videoIndex)}
                          autoPlay muted 
                        >
                          <source src={item?.src} />
                        </video>
                      )}
                      {item?.type === 'video' ? <div style={{position: "absolute", bottom: "1%", padding: "2.5%", left: "1%", borderRadius: "0 0 1rem 1rem", background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1))", width: "92.7%" }}>
                        <Typography style={{fontSize: "16px", color: "white"}}>
                          Beauty and Skin Products
                        </Typography>
                      </div> : null}

                      <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation();
                          if(item?.wishlisted){
                            setRemoveItemToast(true) 
                            updateInWishList(index, allProfileData?.productCollection, "remove")
                          }
                          else {
                            setAddItemToast(true)
                            updateInWishList(index, allProfileData?.productCollection, "add")
                          }
                        }}>
                          {!item?.wishlisted ? <FavoriteBorderOutlined style={{color: item?.type === 'image' ? "black" : "white"}} /> :  <Favorite style={{color: item?.type === 'image' ? "black" : "white"}} /> }
                        </IconButton>
                      </div>
                  </Grid>
        
                  ))}
                </CustomGrid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}></Grid>

      </Grid>
    {/* </div> */}
    </React.Fragment>
  );
};

export default WishList;

