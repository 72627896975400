import React from 'react';
import TopNavbar from '../../components/topNavbar';
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import dummyWhite from "../../assets/images/download.png"


const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
    //   color: "black",
      background: "white",
      position: "relative",
      borderRadius: "5px",
      marginBottom: "4px",
      height: '100vh'
    },
    paperCard: {
        borderRadius: "8px",
        padding: "12px",
        background: "white",
        // height: "82%",
        // width: "85%",
    }
}))


function AnalyticScreen({ isMobile }) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <TopNavbar isMobile={isMobile} screen={"home"}/>
            <Grid container style={{marginTop: "20%"}}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                                    Sales & Commissions
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={6}>
                                    <Paper elevation={1} className={classes.paperCard}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography style={{fontSize: "13px"}}>
                                                    Net Commissions
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" style={{fontWeight: "550"}}>
                                                ₹ 0
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12} container justifyContent='space-between'>
                                                <Grid item>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        Ordered
                                                    </Typography>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        ₹ 0
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        Returned
                                                    </Typography>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        ₹ 0
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={6}>
                                <Paper elevation={1} className={classes.paperCard}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography style={{fontSize: "13px"}}>
                                                    Net Sales
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" style={{fontWeight: "550"}}>
                                                ₹ 0
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12} container justifyContent='space-between'>
                                                <Grid item>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        Ordered
                                                    </Typography>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        ₹ 0
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        Returned
                                                    </Typography>
                                                    <Typography style={{fontSize: "12px"}}>
                                                        ₹ 0
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            
        </React.Fragment>
    )
}


export default AnalyticScreen;