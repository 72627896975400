import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Paper, Typography, Card, CardContent, ImageListItem, ImageList, styled, IconButton, AppBar, Button, Chip, useTheme, Tooltip, Drawer, TextField, CircularProgress } from '@material-ui/core';

import profilePic from "../../assets/images/lissha_prpr.jpg"
import instaLogo from "../../assets/images/instagram.png"
import backLogo from "../../assets/images/back.png"

import dummyPhoto1 from "../../assets/images/dummy_photos/318979404_552826566263952_734592534680343139_n.jpg";
import dummyPhoto2 from "../../assets/images/dummy_photos/328437972_211997201313893_3458513646514237340_n.jpg";
import dummyPhoto3 from "../../assets/images/dummy_photos/318517827_1113616329346439_6160478259138521838_n.jpg";
import dummyPhoto5 from "../../assets/images/dummy_photos/779116795582952285cm3nvcpnjgcatalog.jpg";
import dummyPhoto4 from "../../assets/images/dummy_photos/Vanilla-Caramello_Body-Lotion2_750x.webp";

import dummyPhoto90 from "../../assets/images/dummy_photos/01_3b34de21-6b7b-4a44-afcd-be0f4b4002ce_1024x1024.webp";
import dummyPhoto91 from "../../assets/images/dummy_photos/01-_1_695374cd-8c32-46da-985a-40d3964860d4_750x.webp";


import dummyVideo1 from "../../assets/videos/2989296311522944310.mp4"
import dummyVideo2 from "../../assets/videos/dummy-video122.mp4"

import paperplaneLogo from "../../assets/images/logos/PaperPlaneIcon.png";
import wardrobePng from "../../assets/images/search.png";
import voucherPng from "../../assets/images/voucher.png";
import shopCartPng from "../../assets/images/shopping-cart.png";

import makeupIcon from "../../assets/images/makeup.png";
import makeupIconHover from "../../assets/images/makeup-white.png";

import lipstickIcon from "../../assets/images/shirt.png";
import lipstickIconHover from "../../assets/images/t-shirt-white.png";

import flasIcon from "../../assets/images/lightning.png";


import {useHistory} from "react-router-dom"
import { FavoriteOutlined, FavoriteBorderOutlined, FiberManualRecord, FiberManualRecordOutlined, ArrowForward, FavoriteBorder, Favorite } from "@material-ui/icons"

import { colors } from "../../assets/css/colors"
import CustomToast from '../customToast';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    maxHeight: "100%",
    // overflowY: 'auto',
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflowX: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    background: "#1C1C1C",
    position: "relative",
    marginTop: "5px",
    border: "0.2px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    marginBottom: "5px"
  },
  coverPhoto: {
    height: '20%',
    width: '100%',
  },
  cardHeading: {
    color: 'white',
    fontSize: '18px',
    // fontWeight: 'bold',
  },
  profilePhoto: {
    height: '5.2rem',
    width: '5.2rem',
    borderRadius: '50%',
    border: '2px solid #fff',
    position: "absolute",
    top: "2.5rem",
    left: "38%"

  },
  imagesSection: {
    display: 'flex',
    overflowX: 'scroll',
  },
  imageCard: {
    width: '30%',
    minWidth: '100px',
    marginRight: '8px',
  },
}));

const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: "70vh",
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
    height: 0,
    backgroundColor: "transparent",
    // display: "none"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'black', // Adjust to desired color
    position: "absolute",
  },
});

const ProfilePage = ({isMobile, allProfileData, carouselOpen, setCarouselOpen,  updateWishListItem, setAddItemToast, setRemoveItemToast, openBottomBar, setOpenBottomBar, wishListItem, login, setLogin}) => {
  const classes = useStyles();

  const coverPic = () => {
    const coverPicStyle = {
      textAlign: 'center',
      height: '7.25rem',
      minWidth: '100%',
      borderRadius: '0.5rem',
      backgroundImage: 'url("https://d3g01po1nkka75.cloudfront.net/Cover2.png")',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    };
  
    return (
      <div className="cover-pic-wrapper" style={coverPicStyle}>
        {/* You can add any additional content or components within this div */}
      </div>
    );
  };

  const history = useHistory();

  const curatedVideoRef = useRef([]);
  const videoRef = useRef([]);

  const navBarRef = useRef(null);


  const playVideo = (vRef, vIdx) => {
    try{
      if (vRef.current?.[vIdx]) {
        vRef.current?.[vIdx].play();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const pauseVideo = (vRef, vIdx, check = false) => {
    try{
      if (vRef.current?.[vIdx]) {
        if(!check)
          vRef.current?.[vIdx].pause();
        
        else {
          if(vRef.current?.[vIdx]?.paused)
            vRef.current?.[vIdx].play();
          
          else
            vRef.current?.[vIdx].pause();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  const [curatedMedia, setCuratedMedia] = useState([]);

  const [imagesAndVideos, setImageAndVideos] = useState([]);

  useEffect(() => {
    if(wishListItem?.length && curatedMedia?.length && imagesAndVideos?.length){
      let tempCM = [...curatedMedia].map(v => {
        if(wishListItem?.find(f => f.id === v.id)){
          return {...v, wishlisted: true}
        } else {
          return {...v}
        }
      });
  
      let tempIaV = [...imagesAndVideos].map(v => {
        if(wishListItem?.find(f => f.id === v.id)){
          return {...v, wishlisted: true}
        } else {
          return {...v}
        }
      });
  
      setImageAndVideos([...tempIaV])
      setCuratedMedia([...tempCM])
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const wishlistClick = () => {
    setOpenBottomBar(true)
  }

  const getSpotifyWidget = () => {
    try{
      return (
        <iframe style={{borderRadius:"12px"}} src="https://open.spotify.com/embed/track/5cGBQ9W3XtRuRQFgDklDrI?utm_source=generator" width="100%" height={isMobile ? "80" : "152"} frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      )
    } catch (err) {
      return (
        <Typography color='error'>
          Content Not Allowed
        </Typography>
      )
    }
  }


  const updateInWishList = (index, tempState, setTempState, type = "add") => {
    switch(type) {
      case "add": {

        let updatedTempState = [...tempState];
        updatedTempState[index].wishlisted = true;

        setTempState([...updatedTempState]);

        updateWishListItem(updatedTempState[index], "add")
        
        break;
      }

      case "remove": {

        let updatedTempState = [...tempState];
        updatedTempState[index].wishlisted = false;

        setTempState([...updatedTempState]);

        updateWishListItem(updatedTempState[index], "removeById", 0, updatedTempState?.[index]?.id)
        
        break;
      }
    }
  }

  const [selectedChip, setSelectedChip] = useState(0)


  useEffect(() => {
    if(allProfileData?.name){
      setImageAndVideos([...allProfileData?.productCollection])
      setCuratedMedia([...allProfileData?.curatedCollection])
    }
  }, [allProfileData])



  return (
    <React.Fragment>

      <StoreNav navBarRef={navBarRef} isMobile={isMobile} wishlistClick={wishlistClick} wishListItemExists={wishListItem?.length} login={login} setLogin={setLogin}
        profileName={allProfileData?.name}
        profilePic={allProfileData?.profilePic}
        allProfileData={allProfileData}
      />

    {/* <div className={classes.root}> */}
      <Grid container justifyContent='center' className={classes.root} style={{paddingLeft: isMobile ? null : 10}}>

        <Grid item xs={12} md={3} container style={{position: "relative"}}>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>

              
              <Grid container spacing={1} innerRef={navBarRef}>
                {coverPic()}

                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <img
                    src={allProfileData?.profilePic}
                    alt="Profile Photo"
                    className={classes.profilePhoto}
                  />
                </Grid>

                <Grid item container justifyContent='center' style={{paddingTop: "20px", marginTop: "-15px"}}>
                    <Grid item>
                      <Typography variant="h6" style={{fontWeight: "bold", color: "white"}}>
                        {allProfileData?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
                        <img src={instaLogo} height={15} width={15} style={{marginRight: 5}} /> 
                        <Typography style={{fontSize: "14px"}}>
                          {allProfileData?.followers} Followers
                        </Typography>
                    </Grid>
                    <Grid  item xs={12}></Grid>
                    <Grid item style={{marginTop: 5}}>
                      <Typography style={{fontSize: "14px"}}>
                        Welcome to my Store!
                      </Typography>
                    </Grid>
                </Grid>

                <Grid item style={{position: "absolute", right: 0, top: 0}}>
                  <IconButton onClick={() => !login ? wishlistClick() : history.push(`/${allProfileData?.username}/wishlist`)} style={{position: "relative"}}>
                    <FavoriteBorderOutlined />
                    
                    {wishListItem?.length ? <FiberManualRecord style={{position: "absolute", right: "20%", top: "1%", color: colors.primary, width: 10}} /> : null}

                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} style={{textAlign: "left", paddingBottom: 10}} elevation={0}>
              <Grid container spacing={1}>
                  <Grid item xs={12} style={{textAlign: "left"}}>
                    <Typography variant="h6" className={classes.cardHeading}>
                      Track
                    </Typography>
                    <Typography variant="subtitle1" style={{fontSize: 14}}>
                      Here is what I am listening these days!
                    </Typography>
                  </Grid>   
                  <Grid item xs={12}>
                    {getSpotifyWidget()}
                  </Grid>         
                </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    My Curated Collections
                  </Typography>
                  <Typography variant="subtitle1" style={{fontSize: 14}}>
                    Easy to style collections that are must haves this season. Check'em out!
                  </Typography>

                  {/* <Button variant="text" style={{}}>
                    View All
                  </Button> */}
                </Grid>
                <Grid item xs={12}></Grid>

                <CustomGrid item xs={12}>
                  <CustomGrid className={classes.imagesSection}>
                  {[...curatedMedia].map((item, index) => (
                    <div style={{position: "relative", width: "100%", maxWidth: "100%", marginLeft: "10px", marginBottom: "10px"}}>
                      {item?.type === 'image' ? (
                        <img src={item?.src} style={{height: "21rem", width: "12rem", borderRadius: "0.5rem"}} />
                      ) : (
                        <video ref={(ref) => curatedVideoRef.current[item?.videoIndex] = ref} style={{height: "21rem", width: "12rem", borderRadius: "0.5rem"}}
                        // onMouseEnter={() => playVideo(curatedVideoRef, item?.videoIndex)}
                        // onMouseLeave={() => pauseVideo(curatedVideoRef, item?.videoIndex)}
                        onClick={() => pauseVideo(curatedVideoRef, item?.videoIndex, true)}
                        autoPlay muted 
                      >
                          <source src={item?.src} />
                        </video>
                      )}
                      <div style={{position: "absolute", bottom: 0, left: 0, padding: 10, background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1))", width: "90%"}}>
                        <Typography style={{fontSize: "16px", color: "white"}}>
                          {item?.bottomText ?? "Beauty and Skin Products"}
                        </Typography>
                      </div>

                      <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton onClick={() => {
                          if(item?.wishlisted) {
                            setRemoveItemToast(true)
                            updateInWishList(index, curatedMedia, setCuratedMedia, "remove")
                          }
                          else {
                            setAddItemToast(true)
                            updateInWishList(index, curatedMedia, setCuratedMedia, "add")
                          }
                        }}>
                          {!item?.wishlisted ? <FavoriteBorderOutlined style={{color: "white"}} /> : <Favorite style={{
                            color: "white"
                          }} />}
                        </IconButton>
                      </div>
                    </div>
                  ))}
                  </CustomGrid>
                </CustomGrid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{textAlign: "left"}}>
                  <Typography variant="h6" className={classes.cardHeading}>
                    Shop my looks
                  </Typography>
                  <Typography variant="subtitle1" style={{fontSize: 14}}>
                    Get everything that I try-on in my posts and videos
                  </Typography>
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Chip label={"All Posts"} variant={selectedChip === 0 ? 'default' : "outlined"} onClick={() => setSelectedChip(0)} style={{background: selectedChip === 0 ? colors.primaryGradient : null, cursor: "pointer", color: selectedChip === 0 ? "white" : null}} color='primary'/>
                  <Chip label={"Cosmetics"} onClick={() => setSelectedChip(1)} 
                  // avatar={<img src={selectedChip === 1 ? makeupIconHover : makeupIcon}  style={{background: selectedChip === 1 ? "transparent" : "white", height: 20, width: 20, marginLeft: 10}}/>} 
                  variant={selectedChip === 1 ? "default" : 'outlined'} style={{marginLeft: 5, background: selectedChip === 1 ? colors.primaryGradient : null}} color='primary'/>
                  <Chip label={"Clothes"}  onClick={() => setSelectedChip(2)} 
                  // avatar={<img src={selectedChip === 2 ? lipstickIconHover : lipstickIcon} style={{background: selectedChip === 2 ? "transparent" : "white", height: 20, width: 20, marginLeft: 10}}/>} 
                  variant={selectedChip === 2 ? "default" : 'outlined'} style={{marginLeft: 5, background: selectedChip === 2 ? colors.primaryGradient : null}} color='primary'/>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} container spacing={1} alignItems='center'>
                  {[...imagesAndVideos].filter((f, i) => selectedChip ? (selectedChip === 1 && i < 2 ? true : (selectedChip === 2 && i >= 2 ? true : false)) : true ).map((item, index) => (
                    <Grid item xs={12} key={index} style={{position: "relative", width: "100%", maxWidth: "100%", cursor: "pointer"}} onClick={() => history.push(`/${allProfileData?.username}/view/${item?.productId }`)}>
                      {item?.type === 'image' ? (
                        <Paper style={{borderRadius: "1rem", height: "350px", border: "0.2px solid rgba(255, 255, 255, 0.2)", background: "#1c1c1c"}}>
                          <img src={item?.imageUrl} style={{height: "260px", width: "100%", minWidth: "100%", display: "block", objectFit: "contain"}} />
                          <div style={{padding: 10}}>
                            <Typography style={{fontSize: "14px", color: "white"}}>
                              {item?.name}
                            </Typography>
                            <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                              <Typography style={{fontSize: "14px", color: "white"}}>
                              ₹ {item?.discountedPrice}
                              </Typography>
                              <Typography variant="h5" style={{fontSize: "11px", marginLeft: 5, opacity: 0.5, textDecoration: "line-through"}}>₹ {item?.price} </Typography>
                              <Typography variant="h5" style={{fontSize: "11px", marginLeft: 5, color: colors.secondary}}>({item?.offerPercentage}% OFF)</Typography>
                            </div>
                          </div>
                        </Paper>
                      ) : (
                        <video ref={(ref) => videoRef.current[item?.videoIndex] = ref} style={{height: "350px", width: "100%", borderRadius: "1rem", display: "block", objectFit: "cover"}} 
                          // onMouseEnter={() => playVideo(videoRef, item?.videoIndex)}
                          // onMouseLeave={() => pauseVideo(videoRef, item?.videoIndex)}
                        onClick={() => pauseVideo(videoRef, item?.videoIndex, true)}

                          autoPlay muted 
                        >
                          <source src={item?.src} />
                        </video>
                      )}
                      {item?.type === 'video' ? <div style={{position: "absolute", bottom: "1%", padding: "2.5%", left: "1%", borderRadius: "0 0 1rem 1rem", background: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1))", width: "92.7%" }}>
                        <Typography style={{fontSize: "16px", color: "white"}}>
                          Beauty and Skin Products
                        </Typography>
                      </div> : null}

                      <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton onClick={(event) => {
                          event.stopPropagation();
                          if(item?.wishlisted){
                            setRemoveItemToast(true) 
                            updateInWishList(index, imagesAndVideos, setImageAndVideos, "remove")
                          }
                          else {
                            setAddItemToast(true)
                            updateInWishList(index, imagesAndVideos, setImageAndVideos, "add")
                          }
                        }}>
                          {!item?.wishlisted ? <FavoriteBorderOutlined style={{color: item?.type === 'image' ? "black" : "white"}} /> :  <Favorite style={{color: item?.type === 'image' ? "black" : "white"}} /> }
                        </IconButton>
                      </div>
                  </Grid>
        
                  ))}
                </Grid>

                <Grid item xs={12} container justifyContent='center'>
                  <Grid item style={{display: "flex", alignItems: "center", marginTop: 5}}>
                    <img src={flasIcon} style={{height: "15px"}}/>
                    <Typography variant="caption">
                      Powered by 
                    </Typography>
                    <span style={{
                          // color: "#4D63DD",
                          background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent', 
                          fontWeight: "bold",
                          marginLeft: 3,
                          fontSize: "13px"
                          }}>
                          <i>Paperplane</i></span>
                    {/* <img src={paperplaneLogo} style={{height: "auto", width: "70px", marginLeft: "5px"}} /> */}
                  </Grid>
                </Grid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <BottomBar isMobile={isMobile} open={openBottomBar} setOpen={setOpenBottomBar} setLogin={setLogin} allProfileData={allProfileData}/>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <CarouselBar isMobile={isMobile} isBottomBarOpen={carouselOpen} setIsBottomBarOpen={setCarouselOpen} />
          </Grid>
        </Grid>



      </Grid>

    {/* </div> */}
    </React.Fragment>
  );
};

export default ProfilePage;



export const StoreNav = ({navBarRef, defaultView = false, isMobile, wishlistClick, headerText, wishListItemExists, login, profilePic = "", profileName = "", allProfileData}) => {

  const classes = useStyles()
  const history = useHistory()

  const [stickBar, setStickBar] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 0.001) {
          // This code will run when the component is scrolled out of view
          console.log("Component has scrolled out of view");
          setStickBar(true);
          // Add your code here to perform an action when the component is out of view
        } else {
          setStickBar(false);
        }
      });
    });

    // Start observing the target element (your component's DOM element)
    if(navBarRef){
      if (navBarRef.current) {
        observer.observe(navBarRef.current);
      }
  
      // Clean up the observer when the component unmounts
      return () => {
        if (navBarRef.current) {
          observer.unobserve(navBarRef.current);
        }
      };
    }
  }, []);

  return (
    <Grid container justifyContent='center' style={{pointerEvents: defaultView ? null : (!stickBar ? "none" : null)}}>
        <Grid item xs={12} md={3} style={{position: "relative !important"}}>
          <AppBar elevation={1} style={{
            position: defaultView ? "sticky" : "fixed",
            opacity: defaultView || stickBar ? 1: 0,
            height: defaultView || stickBar ? "auto": 0,
            transition: "opacity 0.3s ease",
            backgroundColor: "#1c1c1c",
            width: isMobile || defaultView ? "auto" : "25%",
            top: 0,
            left: isMobile || defaultView ? 0  : "37.8%",
          }}>
            <Paper className={classes.paper} elevation={0} style={{marginTop: "-5px"}}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item style={{display: "flex", alignItems: "center"}}>
                  {defaultView ? <div style={{marginRight: 5, cursor: "pointer"}} onClick={() => history.goBack()}>
                    <img src={backLogo} height={16} width={18} />
                  </div> : null}

                  {!headerText ? <><img
                    src={profilePic}
                    alt="Profile Photo"
                    height={35}
                    width={35}
                    style={{borderRadius: "50%"}}
                  />
                  <Typography variant="subtitle1" style={{fontWeight: "bold", marginLeft: 10, fontSize: "14px" }}>
                    {profileName}
                    <Grid item style={{display: "flex", alignItems: "center"}}>
                      <img src={flasIcon} style={{height: "15px"}}/>
                      <Typography variant="caption" style={{fontSize: "10px"}}>
                        Powered by 
                        <span style={{
                          // color: "#4D63DD",
                          background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent', 
                          fontWeight: "bold",
                          marginLeft: 3,
                          fontSize: "11px"
                          }}>
                          <i>Paperplane</i>
                        </span>
                      </Typography>
                      {/* <img src={paperplaneLogo} style={{height: "auto", width: "60px", marginLeft: "5px"}} /> */}
                    </Grid>
                  </Typography>
                  </> : 
                  <Typography style={{fontSize: "18px", alignItems: "center", color: "black", marginLeft: 5, marginBottom: 2}}>
                    {headerText}
                  </Typography>
                  }
                </Grid>


                {wishlistClick ? <Grid item xs={12} style={{position: "absolute", right: 0}}>
                  <Button variant='text' startIcon={<FavoriteBorderOutlined style={{fontSize: "13px", position: "relative", color: "white"}}/>} onClick={() => !login ? wishlistClick() : history.push(`/${allProfileData?.username}/wishlist`)}>
                    <Typography style={{fontSize: "12px"}}>
                      My Wishlist
                    </Typography>

                    {wishListItemExists ? <FiberManualRecord style={{position: "absolute", right: 0, top: -10, color: colors.primary, width: 10}} /> : null}
                  </Button> 
                </Grid> : null}
              </Grid>
            </Paper>

          </AppBar>
        </Grid>
      </Grid>
  )
}



export function BottomBar({isMobile, open, setOpen, setLogin, onLogin = null, allProfileData,
  mainText = "Login to your Paperplane account",
  captionText = "Please enter your phone number to continue.",
  buttonText = "Proceed to login"
}) {
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(true);
  const [mobile, setMobile] = useState("");

  const history = useHistory();

  useEffect(() => {
    setIsBottomBarOpen(open);
  }, [String(open)])

  const classes = useStyles();

  const touchStartY = useRef(0);

  const addSwipeListeners = (event) => {
    touchStartY.current = event?.touches?.[0]?.clientY;
  };

  const removeSwipeListeners = (event) => {
    
    const endY = event?.changedTouches?.[0]?.clientY;
    try{
      const deltaY = endY - touchStartY?.current;
      if (deltaY > 50) {
        setIsBottomBarOpen(false);
        if(setOpen)
          setOpen(false)
      }
    } catch (err) {
      console.log("..", err);
    }
  };

  const [loader, setLoader] = useState(false)

  const handleLogin = () => {
    setLoader(true);
    setTimeout(() => {
      if (!onLogin) {
        history.push(`/${allProfileData?.username}/wishlist`)
        setLogin(true)  
      } else {
        onLogin(mobile)
      }

      if(setOpen)
        setOpen(false)
      setIsBottomBarOpen(false)
      setLoader(false)
    }, 1000)
  }


  return (
    <React.Fragment>

      {isBottomBarOpen ? <><div style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 99999,
        pointerEvents: "none"
      }}></div>

      <div 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={{height: "auto", background: "#1c1c1c", zIndex: 999999, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22%", 
        padding: isMobile ? "5%" : "1.4%",
        transition: "height 2s"
        }}>
        <Grid container spacing={1}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <div style={{width: "50px", height: "10px", backgroundColor: "grey", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
                if(setOpen)
                  setOpen(false)
                setIsBottomBarOpen(false)
                }}></div>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Typography style={{fontSize: "16px", fontWeight: "bold"}}>
                {mainText}
            </Typography>
            <Typography style={{fontSize: "13px", marginTop: "10px", opacity: "0.8"}}>
              {captionText}
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <TextField variant="standard" size='small' inputProps={{style: {fontSize: "12px", color: "white", borderColor: "white"}}} value={mobile} onChange={(e) => {
              setMobile(e?.target?.value)
            }} placeholder='Enter 10 digit number' />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
              <Button variant='contained' fullWidth style={{borderRadius: "20px", background: colors.primaryGradient, color: "white"}} disableElevation onClick={() => {
                handleLogin()
              }}
              endIcon={loader ? <CircularProgress style={{color: "white", height: 18, width: 18}} /> : null}
              >
                  {buttonText}
              </Button>
          </Grid>
        </Grid>
      </div></> : null}
    </React.Fragment>
  );
}



export function CarouselBar({isMobile, isBottomBarOpen, setIsBottomBarOpen}) {

  const classes = useStyles();

  const touchStartY = useRef(0);

  const addSwipeListeners = (event) => {
    touchStartY.current = event?.touches?.[0]?.clientY;
  };

  const removeSwipeListeners = (event) => {
    
    const endY = event?.changedTouches?.[0]?.clientY;
    try{
      const deltaY = endY - touchStartY?.current;
      if (deltaY > 50) {
        setIsBottomBarOpen(false);
      }
    } catch (err) {
      console.log("..", err);
    }
  };


  const carouselContent = [
    {
      "image": wardrobePng,
      "heading": "Get product links from influencer's content",
      "subHeading": "Easily find links of looks and products recommended by top influencers"
    },
    {
      "image": voucherPng,
      "heading": "Get coupon codes, offers and deals",
      "subHeading": "Receive price drop alerts and coupons on your favourite products on Whatsapp"
    },
    {
      "image": shopCartPng,
      "heading": "Wishlist and save products for later",
      "subHeading": "Login to create your wishlist and refer it anytime directly via Whatsapp"
    }
  ]

  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      // console.log('swipe', isLeftSwipe ? 'left' : 'right');
      if(isLeftSwipe){
        setSelectedIndex((prevState) => {
          prevState = (prevState < carouselContent.length - 1 ? prevState + 1 : 0);
          return prevState
        })
      } else {
        setSelectedIndex((prevState) => {
          prevState = (prevState > 0 ? prevState - 1 : carouselContent.length - 1);
          return prevState
        })
      }
    //
  }
  
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [autoUpdate, setAutoUpdate] = useState("0")
  useEffect(() => {
    if(isBottomBarOpen) {
      setTimeout(() => {
        setSelectedIndex((prevState) => {
          prevState = (prevState < carouselContent.length - 1 ? prevState + 1 : 0);
          return prevState
        })
        setAutoUpdate(autoUpdate+"0")
      }, 5000)
    }
  }, [isBottomBarOpen, autoUpdate])


  return (
    <React.Fragment>

      {isBottomBarOpen ? <><div style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 99999,
        pointerEvents: "none"
      }}></div>

      <div 
      onTouchStart={addSwipeListeners} onTouchEnd={removeSwipeListeners}
       style={{height: "auto", background: colors?.primaryGradient, zIndex: 999999, position: "fixed", bottom: 0, borderRadius: "20px 20px 0px 0px", width: isMobile ? "90%": "22%", 
        padding: isMobile ? "5%" : "1.4%",
        transition: "height 0.3s ease"
        }}>
        <Grid container spacing={1}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <div style={{width: "50px", height: "10px", backgroundColor: "white", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
                setIsBottomBarOpen(false)
                }}></div>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid item xs={12}>
            <Typography style={{fontSize: "16px", fontWeight: "bold", textAlign: "center", color: "white"}}>
              Hey hi! Welcome to Paperplane
            </Typography>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove} item container xs={12}>
            <Grid item style={{marginTop: 10, marginBottom: 10}}>
              <img src={carouselContent[selectedIndex]?.image} style={{height: "80px", width: "80px"}} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={10}>
              <Typography style={{fontSize: "18px", fontWeight: "bold", textAlign: "left", color: "white"}}>
                {carouselContent[selectedIndex]?.heading}
              </Typography>

              <Typography style={{fontSize: "14px", textAlign: "left", color: "white", marginTop: 5}}>
                {carouselContent[selectedIndex]?.subHeading}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                {[0, 1, 2].map(v => (
                  v === selectedIndex ? <FiberManualRecord style={{color: "white"}} /> : <FiberManualRecordOutlined style={{color: "white"}} />
                ))}
          </Grid>
          {/* <Grid item xs={12}></Grid> */}
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
              <Button variant='contained' fullWidth style={{borderRadius: "20px", background: "white"}} disableElevation endIcon={<ArrowForward color='primary'/>} onClick={() => setIsBottomBarOpen(false)}>
                  <span style={{
                    background: colors.primaryGradient,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent', 
                  }}>
                    Get me in
                  </span>
              </Button>
          </Grid>
          <Grid item xs={12}>
              <Button variant='text' fullWidth style={{borderRadius: "20px", color: "white", marginBottom: "-4%"}} disableElevation onClick={() => setIsBottomBarOpen(false)}>
                  Skip for now
              </Button>
          </Grid>
        </Grid>
      </div></> : null}
    </React.Fragment>
  );
}
