import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom"
import GenericBottomDrawer from "../../components/GenericBottomDrawer";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { Add, Equalizer, Home, Style } from "@material-ui/icons";
import { colors } from "../../assets/css/colors";
import HomeScreen from "../HomeScreen";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom"
import customAxios from "../../axios";
import { ADD_PROFILE_DATA } from "../../redux/constants";
import InstaHandle from "../InstaHandle";
import BrandScreen from "../BrandScreen";
import AnalyticScreen from "../AnalyticScreen";
import ProfileScreen from "../ProfileScreen";


function MainPage({isMobile}) {

    const location = useLocation()    
    const [currPath, setCurrPath] = useState("home")

    const userData = useSelector(state => state?.auth?.user)
    const history = useHistory()

    const dispatch = useDispatch()

    const [pageLoader, setPageLoader] = useState(false)

    useEffect(() => {
        if(location?.pathname){
            let pathname = location.pathname.split("/main/")
            pathname = pathname?.[1]

            if(pathname){
                setCurrPath(pathname)
            }
        }
    }, [location])

    useEffect(() => {
        let token = localStorage.getItem("token");

        if(token) {
            customAxios.get("creator/fetch-profile").then((res) => {
                if(res?.data?.success){
                    // history.push("/main/home");
                    dispatch({type: ADD_PROFILE_DATA, payload: res?.data?.data})
                }
            }).catch(err => {
                console.log(err);
            }) 
        } else {
            history.push("/auth")
        }
    }, [])

    return (
        <React.Fragment>
            <Switch>
              <Route path="/main/home" render={(props) => <HomeScreen {...props} isMobile={isMobile} userData={userData} />} />
              <Route path="/main/brands" render={(props) => <BrandScreen {...props} isMobile={isMobile} userData={userData} />} />
              <Route path="/main/analytics" render={(props) => <AnalyticScreen {...props} isMobile={isMobile} userData={userData} />} />
              <Route path="/main/insta-handle" render={(props) => <InstaHandle {...props} isMobile={isMobile} userData={userData} />} />

              <Route path="/main/profile" render={(props) => <ProfileScreen {...props} isMobile={isMobile} />} />

              <Redirect from="/main/" to={`/main/home`} />
              <Redirect from="/main/*" to={`/main/home`} />
            </Switch>
            <Grid container>
                <Grid item xs={12}>
                    {/* <IconButton style={{background: colors.primary, color: "white", padding: "8px", position: "absolute", bottom: isMobile ? "8%" : "9%", zIndex: 2, left: isMobile ? "46%" : "48.5%" }}>
                        <Add />
                    </IconButton> */}
                    <GenericBottomDrawer open={true} setOpen={null} isMobile={isMobile} elevation={2} maxHeight={"3%"} drawerContent={
                        <React.Fragment>
                            <Grid container>
                                <Grid item xs={4} style={{textAlign: "center", cursor: "pointer"}} onClick={() => {
                                        if(!currPath.includes("home")){
                                            history.push("/main/home");
                                        }
                                    }}>
                                    <Typography style={{color: currPath.includes("home") ? colors.primary : 'grey', fontSize: "14px", marginTop: "-5%"}}>                                       
                                    <Home style={{fontSize: "20px", marginBottom: "-5%"}}/><br />
                                        Home
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center", cursor: "pointer"}} onClick={() => {
                                        if(!currPath.includes("brands")){
                                            history.push("/main/brands");
                                        }
                                    }}>
                                    <Typography style={{color: currPath.includes("brands") ? colors.primary : 'grey', fontSize: "14px", marginTop: "-5%"}}>
                                        <Style style={{fontSize: "20px", marginBottom: "-5%"}} /> <br />
                                        Brands
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center", cursor: "pointer"}} onClick={() => {
                                        if(!currPath.includes("analytics")){
                                            history.push("/main/analytics");
                                        }
                                    }}>
                                    <Typography style={{color: currPath.includes("analytics") ? colors.primary : 'grey', fontSize: "14px", marginTop: "-5%"}}>
                                        <Equalizer style={{fontSize: "20px", marginBottom: "-5%"}} /> <br />
                                        Analytics
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    } />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}


export default MainPage;